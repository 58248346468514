
/*
|--------------------------------------------------------------------------
| Main
|--------------------------------------------------------------------------
|
| The start point of the project. Include jQuery, Bootstrap and required
| plugins and define page object. These files are mandatory.
|
*/
require('../partials/main.js');

//require('../vendors/jquery.js');
//require('../vendors/bootstrap.js');
